<template>
  <div class="h-100">
    <div :key="keyReset" v-if="!isLoading">
      <div v-if="teammate.isLocked" class="mt-4">
        <p>Louis AI is working on that teammate... Come back in a few minutes</p>
      </div>

      <div v-else>
        <b-row no-gutters>
          <b-col cols="8">
            <tip-tap-headline :value.sync="localName" placeholder="No Name" class="tip-tap-headline mt-4" />
          </b-col>

          <b-col cols="4" class="text-right">
            <div class="mt-2 mr-2">
              <base-dropdown class="mt-2 mr-2" @remove="triggerRemoveTeammate" />
            </div>
          </b-col>
        </b-row>

        <h5 class="mt-4 text-uppercase">About You</h5>
        <tip-tap-document
          :project-id="projectId"
          :value.sync="localAboutYou"
          placeholder="Start writing here..."
          format="html"
          class="mt-2"
          style="margin-left: 35px"
        />

        <h5 class="mt-4 text-uppercase">
          <span v-if="teammate.type === TEAMMATES.COPYWRITER">Writing Rules</span>
          <span v-else-if="teammate.type === TEAMMATES.ILLUSTRATOR">Design Rules</span>
        </h5>
        <tip-tap-document
          :project-id="projectId"
          :value.sync="localWritingRules"
          placeholder="Start writing here..."
          format="html"
          class="mt-2"
          style="margin-left: 35px"
        />

        <div>
          <b-button
            v-if="teammate.type === TEAMMATES.COPYWRITER"
            @click="triggerExtractWritingRules"
            variant="outline-primary"
            class="mr-3 float-right text-uppercase"
            :disabled="isSending"
          >
            Update Writing Style
          </b-button>

          <h5 class="mt-4 text-uppercase">Examples</h5>
        </div>

        <div class="p-4">
          <search-multiple
            v-if="teammate.type === TEAMMATES.COPYWRITER"
            :value.sync="localExamples"
            :options="itemsSorted"
            label="name"
            class="mt-2"
          />

          <div v-else-if="teammate.type === TEAMMATES.ILLUSTRATOR">
            <b-row>
              <b-col v-for="file of teammate.examplesFiles" :key="file.id" cols="6" md="4">
                <b-img :src="file.url" class="rounded-lg" fluid />
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { GET, UPDATE, REMOVE, TRAIN } from "@/store/actions";

import { BRow, BCol, BButton, BImg } from "bootstrap-vue";

import { TEAMMATES } from "@/constants/teammates";

import { generateKey } from "@/helpers/utils";

import BaseDropdown from "@/components/Base/Dropdown";
import TipTapHeadline from "@/components/Forms/TipTap/Headline";
import TipTapDocument from "@/components/Forms/TipTap/Document/";
import SearchMultiple from "@/components/Forms/Base/BaseSelect/SearchMultiple";

export default {
  name: "Teammate",
  components: {
    BRow,
    BCol,
    BButton,
    BImg,
    BaseDropdown,
    TipTapHeadline,
    TipTapDocument,
    SearchMultiple,
  },
  computed: {
    ...mapState({ documents: (state) => state.projectsDocuments.items }),
    ...mapGetters("projectsTeammates", ["teammateById"]),
    ...mapGetters("projectsDocuments", ["itemsSorted"]),
    projectId() {
      return this.$route.params.projectId;
    },
    teammateId() {
      return this.$route.params.teammateId;
    },
    teammate() {
      const { teammateId: id } = this;
      return this.teammateById({ id });
    },

    localName: {
      get() {
        return this.teammate?.name;
      },
      set(name) {
        const { projectId, teammateId } = this;
        this.updateTeammate({ projectId, teammateId, name });
      },
    },
    localAboutYou: {
      get() {
        return this.teammate?.aboutYou;
      },
      set(aboutYou) {
        const { projectId, teammateId } = this;
        this.updateTeammate({ projectId, teammateId, aboutYou });
      },
    },
    localWritingRules: {
      get() {
        return this.teammate?.writingRules;
      },
      set(writingRules) {
        const { projectId, teammateId } = this;
        this.updateTeammate({ projectId, teammateId, writingRules });
      },
    },
    localExamples: {
      get() {
        const { documents } = this;
        const { examplesIds = {} } = this.teammate;
        const result = [];

        Object.keys(examplesIds).forEach((exampleId) => {
          result.push({ id: exampleId, ...documents[exampleId] });
        });

        return result;
      },
      set(examples) {
        const { projectId, teammateId } = this;
        const examplesIds = {};

        examples.forEach(({ id }) => (examplesIds[id] = true));
        this.updateTeammate({ projectId, teammateId, examplesIds });
      },
    },
  },
  data: () => ({
    errorText: null,

    isLoading: true,
    isSending: false,

    keyReset: generateKey(),

    TEAMMATES,
  }),
  mounted() {
    this.init();
  },
  watch: {
    teammateId() {
      this.init();
      this.keyReset = generateKey();
    },
  },
  methods: {
    ...mapActions("projectsTeammates", {
      getTeammate: GET,
      updateTeammate: UPDATE,
      removeTeammate: REMOVE,
    }),
    ...mapActions("projectsTasksTools", { trainAITeammate: TRAIN }),
    async init() {
      this.isLoading = true;

      const { projectId, teammateId } = this;
      await this.getTeammate({ projectId, teammateId });

      this.isLoading = false;
    },

    async triggerRemoveTeammate() {
      this.isSending = true;

      const { projectId, teammateId } = this;
      await this.removeTeammate({ projectId, teammateId });

      this.$router.push({ name: "Project" });
      this.isSending = false;
    },

    async triggerExtractWritingRules() {
      this.isSending = true;

      const { projectId, teammateId, localExamples: examples } = this;
      const examplesIds = examples.map((item) => item.id);

      await this.trainAITeammate({ projectId, teammateId, examplesIds });

      this.isSending = false;
    },
  },
};
</script>
