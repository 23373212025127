<template>
  <div class="base-select-native w-100" :class="['theme-' + theme]">
    <vue-select
      :multiple="isMultiple"
      :label="label"
      :placeholder="placeholder"
      v-model="localValue"
      :options="options"
      :style="styleCustom"
    />
  </div>
</template>

<script>
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "SelectSearch",
  components: { VueSelect },
  props: {
    label: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
    options: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [Array],
      default: () => [],
    },

    theme: {
      type: String,
      default: "white",
      validator: (v) => ["white", "beige", "dark"].includes(v),
    },
    styleCustom: {
      type: String,
      default: "",
    },

    isMultiple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(payload) {
        this.$emit("update:value", payload);
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_settings";

.base-select-native {
  .vs__deselect {
    fill: white !important;
    margin-left: 10px;
    margin-right: 10px;
  }

  .vs__selected {
    background-color: $dark-valhalla;
    color: white;
  }

  .vs__dropdown-menu,
  .vs__dropdown-toggle {
    background-color: $black;
    color: $white;
    border: 1px $gray-darker solid;
  }
}
</style>
